.multiselect-button-dropdown {
  padding: 0.45rem 0.5rem !important;
  border: none !important;
  box-shadow: none !important;
  font-family: 'helveticaneuelight' !important;
  font-size: 0.9rem !important;
  color: #606060 !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  background: #e5e5e5 !important;
  border-radius: 0.5rem !important;
  position: relative !important;
  cursor: pointer !important;
}

.multiselect-button-dropdown:focus, .multiselect-list-item:focus, .multiselect-list-item-label:focus,
.multiselect-button-select-all, .multiselect-reset-button {
  outline: none !important;
}

.multiselect-button-dropdown-wrapper {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  position: relative;
}

.multiselect-section-wrapper {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border: none !important;
  background-color: white !important;
  padding: 0 !important;
  box-shadow: 0 1px 16px rgba(0,0,0,0.16) !important;
  border-radius: 0.5rem !important;
  background-color: #767676 !important;
  animation: fadeInFromNone 500ms !important;
}

.multiselect-section-wrapper > div {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.multiselect-section-wrapper.deanimate {
  animation: fateOutToNone 500ms !important;
}

.multiselect-badge { 
  border-radius: 1rem !important;
  font-size: 0.7rem !important;
  padding: 0 !important;
  display: inline-block !important;
  width: 1.15rem !important;
  height: 1.15rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: normal !important;
  line-height: 1 !important;
  color: rgb(255, 255, 255) !important;
  vertical-align: middle !important;
  background-color: rgb(0, 0, 0) !important;
  margin-left: 0.3125rem !important;
}

/* .multiselect-badge:after {
  content: "" !important;
  display: inline-block !important;
  margin-left: 10px !important;
  transform: rotate(45deg) !important;
  padding: 3px !important;
  border-style: solid !important;
  border-color: black !important;
  border-image: initial !important;
  border-width: 0px 3px 3px 0px !important;
  position: absolute !important;
  right: 20px !important;
} */


.multiselect-button-select-all, .multiselect-reset-button {
  font-family: 'helveticaneuelight' !important;
  font-size: 0.9rem !important;
  color: black !important;
  padding: 1rem !important;
  color: white !important;
  background: unset !important;
  border: none !important;
}

.multiselect-list {
  max-height: calc((((100vh - 4.125rem) - 2.625rem) - 3.125rem) - 0px) !important;
  padding-left: 0px !important;
  margin: 0px !important;
  overflow: auto !important;
  list-style: none !important;
}

.multiselect-list-item {
  display: flex;
  font-family: 'helveticaneuebold' !important;
  font-size: 1rem !important;
  color: white !important;
  list-style: none !important;
  padding: 0 1rem;
}

.multiselect-list-item:hover {
  background-color: #B3B3B3 !important;
}

.multiselect-list-item-label {
  font-family: 'helveticaneuelight' !important;
  font-size: 0.9rem !important;
  padding: 0.5rem 1rem !important;
  color: white !important;

  display: flex !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  line-height: 1 !important;
  white-space: nowrap !important;
  cursor: pointer !important;
  padding: 0.625rem 0px !important;
  margin: 1px !important;
  width: 100% !important;
}

.multiselect-list-item-label.is-checked:after {
  content: "" !important;
  display: block !important;
  width: 5px !important;
  height: 10px !important;
  margin-right: 3px !important;
  transform: rotate(45deg) !important;
  border-style: solid !important;
  border-color: rgb(0, 0, 0) !important;
  border-image: initial !important;
  border-width: 0px 3px 3px 0px !important;
}

.multiselect-list-item-checkbox {
  visibility: hidden !important;
}

@keyframes fadeInFromNone {
  0% {
      display: none !important;
      opacity: 0 !important;
  }

  1% {
      display: block !important;
      opacity: 0 !important;
  }

  100% {
      display: block !important;
      opacity: 1 !important;
  }
}

@keyframes fateOutToNone {
  0% {
    opacity: 1 !important;
  }

  99% {
    opacity: 0 !important;
  }

  100% {
    display: none !important;
    opacity: 0 !important;
  }
}