
body * {
  font-family: 'helveticaneuemedium';
}

body {
  overflow:hidden;
  position:fixed;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 992px) { 
  body {
    overflow: visible;
    position: relative;
  }
}


a {
  text-decoration: none;
  font-family: 'helveticaneuelight';
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 2px 0;
}

svg {
  fill: inherit !important;
}

@media screen and (max-width:479px) {
  .hide-xs{ display:none !important; }
}
/* hidden-sm */
@media screen and (min-width:480px) and (max-width:768px) {
  .hide-sm{ display:none !important; }
}
/* hidden-md */
@media screen and (min-width:769px) and (max-width:992px) {
  .hide-md{ display:none !important; }
}
/* hidden-lg */
@media screen and (min-width:993px) and (max-width:1200px) {
  .hide-lg{ display:none !important; }
}
/* hidden-xl */
@media screen and (min-width:1201px) {
  .hide-xl{ display:none !important; }
}
